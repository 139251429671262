export const getLogoByDomain = () => {
    const domainLogoMapping = {
        'ag.jh20.in': '/logos/logo.png',
        'ag.20wickets.in': '/logos/20wickets.png',
    };

    // Get the current domain
    const currentDomain = window.location.hostname;

    // Return the corresponding logo or a default one
    return domainLogoMapping[currentDomain] || '/logos/logo.png';
};
