import React, { useEffect, useState } from "react";
import { json, useLocation, useParams } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Appconfig from '../../../../config/config'
import axios from 'axios';
import { Switch } from "antd";
import SkyLoader from '../../../../components/sky-loader';


const ManualOddsEventData = () => {
    const params = useParams();
    const [markets, setMarkets] = useState([
        {
            market_name: "",
            event_id: params?.event_id,
            runners: [
                { runner_name: "", back_1_price: "", lay_1_price: "", back_1_size: "", lay_1_size: "" },
            ],
        },
    ]);
    const [skyLoading, setSkyLoading] = useState(false);


    const validateMarkets = () => {
        for (const market of markets) {
            console.log('markets', market);
            if (!String(market?.market_name)?.trim()) {
                NotificationManager.error('Market name is required.', '', 3000);
                return false;
            }
            for (const runner of market.runners) {
                console.log('market.runners', market.runners);
                if (!String(runner?.runner_name)?.trim() || !String(runner?.back_1_price)?.trim() || !String(runner?.lay_1_price)?.trim() || !String(runner?.back_1_size)?.trim() || !String(runner?.lay_1_size)?.trim()) {
                    NotificationManager.error('All runner fields are required.', '', 3000);
                    return false;
                }
            }

            if (market.runners.length < 2) {
                NotificationManager.error('Minimum Two Runners Required.', '', 3000);
                return false;
            }
        }
        return true;
    };


    const addMarket = () => {
        setMarkets([
            ...markets,
            { market_name: "", event_id: params?.event_id, runners: [{ runner_name: "", back_1_price: "", lay_1_price: "", back_1_size: "", lay_1_size: "" }] },
        ]);
    };

    const removeMarket = (index) => {
        if (index > 0) {
            const updatedMarkets = markets.filter((_, marketIndex) => marketIndex !== index);
            setMarkets(updatedMarkets);
        }
    };

    const updateMarket = (index, field, value) => {
        const updatedMarkets = [...markets];
        updatedMarkets[index][field] = value;
        setMarkets(updatedMarkets);
    };

    const updateRunner = (marketIndex, runnerIndex, field, value) => {
        const updatedMarkets = [...markets];
        updatedMarkets[marketIndex].runners[runnerIndex][field] = value;
        setMarkets(updatedMarkets);
    };

    const addRunner = (marketIndex) => {
        const updatedMarkets = [...markets];
        updatedMarkets[marketIndex].runners.push({ runner_name: "", back_1_price: "", lay_1_price: "", back_1_size: "", lay_1_size: "" });
        setMarkets(updatedMarkets);
    };

    const removeRunner = (marketIndex, runnerIndex) => {
        const updatedMarkets = [...markets];
        updatedMarkets[marketIndex].runners = updatedMarkets[marketIndex].runners.filter((_, index) => index !== runnerIndex);
        setMarkets(updatedMarkets);
    };

    const handleSubmit = () => {
        if (validateMarkets()) {
            setSkyLoading(true);
            console.log("Submitted Data:", markets);
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}manualMarkets/addManualMarkets`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(markets),
            };

            axios(config)
                .then(function (response) {
                    if (response.data.result) {
                        NotificationManager.success(response.data.resultMessage, '', 3000);
                        getManualMarkets();
                    }
                    else {
                        NotificationManager.error(response.data.resultMessage, '', 3000);
                    }
                    setSkyLoading(false);

                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    };


    const getManualMarkets = () => {
        let data = JSON.stringify({
            event_id: params?.event_id
        })
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}manualMarkets/getManualMarkets`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    setMarkets(response.data.resultData);
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    };

    const deleteMarket = (id) => {

        setSkyLoading(true);

        let data = JSON.stringify({
            _id: id
        })
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}manualMarkets/deleteManualMarket`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    NotificationManager.success(response.data.resultMessage, '', 3000);
                    getManualMarkets();
                }
                else {
                    NotificationManager.error(response.data.resultMessage, '', 3000);
                }

                setSkyLoading(false);


            })
            .catch(function (error) {
                console.log(error);
            });

    };

    function updateStatus(status, event_id, market_id) {
        setSkyLoading(true);

        let data = JSON.stringify({
            event_id: event_id,
            market_id: market_id,
            status: status ? 'OPEN' : 'SUSPENDED',
        })
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}manualMarkets/updateMarketBookOddsStatus`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    NotificationManager.success(response.data.resultMessage, '', 3000);
                    getManualMarkets();
                }
                else {
                    NotificationManager.error(response.data.resultMessage, '', 3000);
                }
                setSkyLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getManualMarkets();
    }, [])

    return (
        <> <NotificationContainer />
            {
                skyLoading && <SkyLoader />
            }
            <div className="p-4 space-y-6">
                <h3 className="text-2xl font-semibold">{params?.event_name}</h3>

                {markets.map((market, marketIndex) => (
                    <div key={marketIndex} className="border border-gray-300 p-4 rounded-lg shadow-md relative">
                        <h2 className="text-xl font-bold mb-4">Market Odds

                        </h2>
                        <div className="absolute top-[20px] right-[10px]">
                            <Switch checked={market?.status === "OPEN" ? true : false} onChange={(e) => updateStatus(e, market?.event_id, market?.market_id)} />
                        </div>


                        {marketIndex > 0 && (
                            <button
                                onClick={() => removeMarket(marketIndex)}
                                className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                            >
                                ✕
                            </button>
                        )}

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Market Name</label>
                            <input
                                type="text"
                                value={market.market_name}
                                onChange={(e) => updateMarket(marketIndex, "market_name", e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter market name"
                            />
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold mb-2">Runners</h3>
                            {market.runners.map((runner, runnerIndex) => (
                                <div key={runnerIndex} className="mb-4 relative">
                                    <button
                                        onClick={() => removeRunner(marketIndex, runnerIndex)}
                                        className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                                    >
                                        ✕
                                    </button>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Runner Name</label>
                                    <input
                                        type="text"
                                        value={runner.runner_name}
                                        onChange={(e) => updateRunner(marketIndex, runnerIndex, "runner_name", e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded-lg mb-2 focus:ring-2 focus:ring-blue-500"
                                        placeholder="Enter runner name"
                                    />
                                    <div className="flex space-x-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Back Value</label>
                                            <input
                                                type="number"
                                                value={runner.back_1_price}
                                                onChange={(e) => updateRunner(marketIndex, runnerIndex, "back_1_price", e.target.value)}
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter back value"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Lay Value</label>
                                            <input
                                                type="number"
                                                value={runner.lay_1_price}
                                                onChange={(e) => updateRunner(marketIndex, runnerIndex, "lay_1_price", e.target.value)}
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter lay value"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Back Size</label>
                                            <input
                                                type="text"
                                                value={runner.back_1_size}
                                                onChange={(e) => updateRunner(marketIndex, runnerIndex, "back_1_size", e.target.value)}
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter back size"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Lay Size</label>
                                            <input
                                                type="text"
                                                value={runner.lay_1_size}
                                                onChange={(e) => updateRunner(marketIndex, runnerIndex, "lay_1_size", e.target.value)}
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter lay size"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button
                                onClick={() => addRunner(marketIndex)}
                                className="text-blue-500 hover:underline mt-2"
                            >
                                + Add Runner
                            </button>
                            {
                                market.id &&
                                <button
                                    onClick={() => deleteMarket(market.id)}
                                    className="text-blue-500 hover:underline mt-2 float-right"
                                >
                                    Delete
                                </button>
                            }

                        </div>
                    </div>
                ))}
                <button
                    onClick={addMarket}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                >
                    + Add Market
                </button>
                <button
                    onClick={handleSubmit}
                    className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Update
                </button>
            </div>
        </>
    );
};

export default ManualOddsEventData;
